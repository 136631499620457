import axios from 'axios';
import { MessageBox, Message} from 'element-ui'
export default async function request(params) {
    return axios(params);
}
axios.interceptors.response.use(
    response => {
        // 状态码为200时正常返回
        if (response.status === 200) {
            return response;
        } else {
            // 状态码不是200时弹出错误提示，并返回拒绝的Promise
            Message.warning('网络异常，请稍后重试');
            return Promise.reject("");
        }
    },
    error => {
        // 处理响应错误的情况
        if (error.response) {
            Message.warning('网络异常，请稍后重试');
        } else {
            Message.warning('请求失败，请检查网络连接');
        }
        return Promise.reject("");
    }
);
