import request from "@/utils/request";

export function getDictList() {
    return request({
        url: '/api/careerAsk/careerDict/selectDictList?dictType=class_type',
        method: 'GET',
    })
}

export function selectRecordPage(params) {
    return request({
        url: '/api/careerAsk/institutionRecord/selectPage',
        method: 'GET',
        params: params
    })
}
export function selectDetail(params) {
    return request({
        url: '/api/careerAsk/institutionAttachments/selectDetail',
        method: 'GET',
        params: params
    })
}
