<template>
  <div class="normal" :style="{'width':(screenWidth * 1) +'px' }">
    <el-form label-width="68px" size="medium" :inline="true" class="search-area" label-position="left">
      <el-form-item>
        <el-select v-model="queryParams.classType" placeholder="请选择种类" clearable @change="handleQuery">
          <el-option
              v-for="item in options"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
            v-model="queryParams.address"
            placeholder="请输入地理位置"
            clearable
            @input="handleQuery"
            @keyup.enter.native="handleQuery"

        />
      </el-form-item>
      <el-form-item>
        <el-input
            v-model="queryParams.name"
            placeholder="请输入机构名称"
            clearable
            @input="handleQuery"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>


      <el-form-item>
        <el-input
            v-model="queryParams.jobName"
            placeholder="请输入职业/方向名称"
            clearable
            @input="handleQuery"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-input
            v-model="queryParams.allSearch"
            placeholder="请输入查询条件"
            clearable
            @input="handleQuery"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>

    </el-form>
    <el-row :style="{'width':(screenWidth * 1) +'px' ,'height':(screenHeight * 0.8) +'px'}">
      <el-col style="width: 55%;padding-left:10px;overflow-x: auto">
        <el-table v-loading="loading" border style="width: 100%" :data="dealRecord(recordList)"
                  @row-click="selectRecord"
                  :cell-class-name="tableRowClassName" :max-height="screenHeight * 0.8" ref="record"
                  class="record_table" :span-method="spanRecordMethod">
          <el-table-column label="种类" align="center" width="80" :key="1">
            <template slot-scope="scope">
              <span>{{ scope.row.classTypeName }}<a style="color: red">{{ scope.row.filingType == 1 ? "(国)" : "" }}</a></span>
            </template>
          </el-table-column>
          <el-table-column label="区域" align="center" width="80" :key="2">
            <template slot-scope="scope">
              <a v-html="highlightText(highlightText(scope.row.province,queryParams.address),queryParams.allSearch)"></a>
              <a
                  v-html="highlightText(highlightText(scope.row.city,queryParams.address),queryParams.allSearch)"></a>
            </template>
          </el-table-column>
          <el-table-column label="机构名称" align="center" width="150" :key="3">
            <template slot-scope="scope">
              <span v-html="highlightText(highlightText(scope.row.name,queryParams.name),queryParams.allSearch)"></span>
            </template>
          </el-table-column>
          <el-table-column label="机构类型" align="center" prop="unitSocietyTypeName" width="100" :key="4"/>

          <el-table-column label="报名条件" align="center" ::key="5" width="100">
            <template slot-scope="scope">
              <span style="color: #3a8ee6"
                    @click="selectDetail(scope.row.jobCode,1)" v-if="scope.row.jobCode">报名条件</span>
            </template>
          </el-table-column>
          <el-table-column label="职业名称" align="center" ::key="5" width="150">
            <template slot-scope="scope">
              <span
                    v-html="highlightText(highlightText(scope.row.jobName,queryParams.jobName),queryParams.allSearch)"
              ></span>
            </template>
          </el-table-column>
          <el-table-column label="工种/职业方向名称" align="center" :key="6">
            <template slot-scope="scope">
                <span
                    v-html="highlightText(highlightText(scope.row.occuName,queryParams.jobOccupationName),queryParams.allSearch)"></span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col style="width: 35%;margin: 0 2.5%;">
        <el-row class="name_area" :style="{'height':(screenHeight * 0.1) +'px'}">
          <a v-html="dealName(index)" />
        </el-row>
        <el-row :style="{'height':(screenHeight * 0.7) +'px'}">
          <el-table v-loading="loading" :data="dealDetail(index)"
                    :span-method="spanMethod" border :max-height="screenHeight * 0.7" ref="detail" class="detail_table"
                    :cell-class-name="tableDetailRowClassName">

            <el-table-column label="职业名称" align="center">
              <template slot-scope="scope">
              <span style="color: #3a8ee6"
                    v-html="highlightText(highlightText(scope.row.jobName,queryParams.jobName),queryParams.allSearch)"
                    @click="selectDetail(scope.row.jobCode)"></span>
              </template>
            </el-table-column>
            <el-table-column label="职业代码" align="center" prop="jobCode"/>
            <el-table-column label="工种/职业方向名称" align="center" width="200">
              <template slot-scope="scope">
                <span
                    v-html="highlightText(highlightText(scope.row.name,queryParams.jobOccupationName),queryParams.allSearch)"></span>
              </template>
            </el-table-column>
            <el-table-column label="级别" align="center" prop="grade">
            </el-table-column>


          </el-table>
        </el-row>
      </el-col>
    </el-row>
    <el-row>
      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="selectRecordPage"
          @scrollToBack="scrollTo"
      />
    </el-row>
    <el-dialog custom-class="pdf-Dialog" :title="pdfName" :visible.sync="dialogShow" center @closed="handleClose">
      <iframe :src="pdfUrl" frameborder="0" style="width: 100%; height: 100%"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import {getDictList, selectRecordPage, selectDetail} from "@/api/career"
import Pagination from "@/components/child/page.vue"

export default {
  name: 'HelloWorld',
  components: {
    Pagination,
  },
  props: {
    msg: String
  },
  data() {
    return {

      loading: false,
      options: [],
      index: 0,
      total: 0,
      queryParams: {
        address: "",
        classType: "",
        name: "",
        jobName: "",
        jobOccupationName: "",
        allSearch: "",
        pageNum: 1,
        pageSize: 10
      },
      recordList: [],
      // 屏幕宽度
      screenWidth: window.innerWidth,
      // 屏幕高度
      screenHeight: window.innerHeight,
      dialogShow: false,
      pdfUrl: "",
      pdfName: "",
      message: "",
      requestFlag: 0
    }
  },
  created() {
    this.getDictList()
    this.selectRecordPage()
  },
  mounted() {
  },
  methods: {

    showPdf() {
      this.dialogShow = true
    },
    handleClose() {
      this.pdfName = ""
      this.pdfUrl = ""
    },
    getDictList() {
      getDictList().then(res => {
        if (res.data.data) {
          this.options = res.data.data
          // console.log(this.options)
        }
      }).catch(err => {
      })
    },
    selectRecordPage() {
      this.index = 0
      let currentTimeMillis = new Date().getTime();
      // console.log(currentTimeMillis);
      this.requestFlag = currentTimeMillis;
      selectRecordPage(this.queryParams).then(res => {
        if (this.requestFlag != currentTimeMillis) {
          return
        }
        if (res.data.data) {
          this.total = res.data.data.totalPage
          this.recordList = res.data.data.data

        }
        this.$nextTick(() => {
          this.$refs.record.doLayout()
        })

      }).catch(err => {
      })
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.scrollTo()

      this.selectRecordPage();
    },
    scrollTo() {
      this.$refs.detail.bodyWrapper.scrollTop = 0;
      this.$refs.record.bodyWrapper.scrollTop = 0;
    },
    selectRecord(row, event, column) {
      if (row.index != this.index) {
        this.$refs.detail.bodyWrapper.scrollTop = 0;
      }

      this.index = row.index
    },
    tableRowClassName({row, column, rowIndex, columnIndex}) {
      //把每一行的索引放进row
      // row.index = rowIndex;
      if (row.index == this.index && columnIndex == 0) {
        return "success-row"
      }
      if (row.index % 2 == 0 && columnIndex == 0) {
        return "weight-row"
      }
      return "normal-row"
    },

    tableDetailRowClassName({row, column, rowIndex, columnIndex}) {
      //把每一行的索引放进row
      row.index = rowIndex;
      // console.log(rowIndex)
      // console.log(columnIndex)
      if (rowIndex % 2 == 0 && columnIndex >= 2) {
        return "weight-row"
      }
      return "normal-row"
    },
    // 自定义合并单元格的方法
    spanMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex == 0 || columnIndex == 1) { // 假设第3列（索引为2）是需要展示多条数据的列
        let total = row.total
        let rowLength = 0
        if (total) {
          rowLength = total
        }
        return {
          rowspan: rowLength, // 垂直方向合并单元格数
          colspan: 1 // 水平方向合并单元格数，根据该行的 hobbies 数组长度决定
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
    spanRecordMethod({row, column, rowIndex, columnIndex}) {

      if (columnIndex < 6) { // 假设第3列（索引为2）是需要展示多条数据的列
        let total = row.total
        let rowLength = 0

        if (total) {
          // console.log(rowIndex, "===>", columnIndex, "====>", total)
          rowLength = total
        }
        return [rowLength, 1]
      } else {
        return [1, 1]
      }
    },
    highlightText(text, query) {
      return text
      // if (!query || text == null) {
      //   return text;
      // }
      // // 正则是关键
      // const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      // const regex = new RegExp(escapedQuery, 'ig');
      //
      // let newAudioText = text.replace(regex, match => `<span style="color: #d6000f;font-weight:bold">${match}</span>`);
      // return newAudioText
    },
    dealName(index) {
      if (!this.recordList || this.recordList.length == 0) {
        return ""
      }
      return this.recordList[index].name
    },
    dealRecord(recordList) {
      if (!recordList || recordList.length == 0) {
        return []
      }
      let targetArr = []
      recordList.forEach((item, index) => {
        const classTypeName = item.classTypeName
        const filingType = item.filingType
        const province = item.province
        const city = item.city
        const name = item.name

        let unitSocietyTypeName = item.unitSocietyTypeName
        let institutionSkillLevelRecord = item.institutionSkillLevelRecord;
        if (institutionSkillLevelRecord) {
          let jobName = institutionSkillLevelRecord.jobName;
          let jobCode = institutionSkillLevelRecord.jobCode;
          let occupationDetailList = institutionSkillLevelRecord.occupationDetailList
          if (occupationDetailList && occupationDetailList.length > 0) {
            const length = occupationDetailList.length
            // console.log(length)
            for (let i = 0; i < occupationDetailList.length; i++) {
              const occupationDetail = occupationDetailList[i]
              let detailObject = {
                occuName: occupationDetail.name,
                grade: occupationDetail.grade,
                index: index,
                total: 0
              }
              detailObject.name = name
              detailObject.jobName = jobName
              detailObject.jobCode = jobCode

              detailObject.classTypeName = classTypeName
              detailObject.filingType = filingType
              detailObject.province = province
              detailObject.city = city
              detailObject.unitSocietyTypeName = unitSocietyTypeName
              if (i == 0) {
                detailObject.total = length
              }
              targetArr.push(Object.assign(detailObject))
            }
          } else {
            let detailObject = {index: index}
            detailObject.jobName = jobName
            detailObject.jobCode = jobCode
            detailObject.occuName = ""
            detailObject.grade = ""
            detailObject.total = 1
            detailObject.name = name
            detailObject.classTypeName = classTypeName
            detailObject.filingType = filingType
            detailObject.province = province
            detailObject.city = city
            detailObject.unitSocietyTypeName = unitSocietyTypeName
            targetArr.push(Object.assign(detailObject))
          }
        } else {
          let detailObject = {index: index}
          detailObject.jobName = ""
          detailObject.jobCode = ""
          detailObject.occuName = ""
          detailObject.grade = ""
          detailObject.total = 1
          detailObject.name = name
          detailObject.classTypeName = classTypeName
          detailObject.filingType = filingType
          detailObject.province = province
          detailObject.city = city
          detailObject.unitSocietyTypeName = unitSocietyTypeName
          targetArr.push(Object.assign(detailObject))
        }


      })
      // console.log(JSON.stringify(targetArr))
      // console.log(targetArr)

      return targetArr
    },


    dealDetail(index) {
      if (!this.recordList || this.recordList.length == 0) {
        return []
      }
      let record = this.recordList[index]
      if (record && record.institutionSkillLevelRecordList) {
        let detailList = record.institutionSkillLevelRecordList
        let targetDetailArr = []
        detailList.forEach(item => {
          let jobName = item.jobName
          let jobCode = item.jobCode
          let occupationDetailList = item.occupationDetailList;
          if (occupationDetailList) {
            let length = occupationDetailList.length
            for (let i = 0; i < occupationDetailList.length; i++) {
              let occupationDetail = occupationDetailList[i]
              let detailObject = {
                jobName: jobName,
                jobCode: jobCode,
                name: occupationDetail.name,
                grade: occupationDetail.grade,
              }
              if (i == 0) {
                detailObject.total = length
              }
              targetDetailArr.push(detailObject)
            }
          }
        })
        return targetDetailArr
      }
    },
    selectDetail(code, type) {
      if (!code) {
        return
      }
      selectDetail({jobCode: code}).then(res => {
        if (!res.data.data) {
          if (this.message != "") {
            this.message.close()
            this.message = ""
          }
          this.message = this.$message('暂无附件');
        } else {
          // console.log(res)
          let pdfUrl = res.data.data.attachmentsUrl
          if (type == 1) {
            pdfUrl = pdfUrl + "#page=" + res.data.data.importantPage
          }
          let pdfName = res.data.data.jobName
          this.pdfUrl = pdfUrl
          this.pdfName = pdfName
          this.showPdf()

        }
      }).catch(err => {
      })

    }
  }
}
</script>
<style>
.record_table {
  width: 100%;

  .success-row {
    background: linear-gradient(180deg, rgba(111, 238, 162, 1) 0%, rgba(164, 241, 165, 1) 100%);
    //box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
    height: 60px !important;
  }

  .normal-row {

    height: 60px !important;
  }

  .weight-row {
    background: #f4f4f5;
    height: 60px !important;
  }


}

.detail_table {
  .weight-row {
    background: #f4f4f5;
    height: 10% !important;
  }

  .normal-row {
    height: 10% !important;
  }


}

/deep/ .el-select-dropdown__item {
  color: #544c4c !important;
  font-size: 16px !important;

}

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/deep/ .pdf-Dialog {
  height: 90vh !important;
  width: 80vw !important;
  margin-top: 5vh !important;
}


/deep/ .el-table--enable-row-hover .el-table__body tr > td:hover {
  background: linear-gradient(180deg, rgba(74, 240, 215, 1) 0%, rgba(168, 231, 222, 1) 100%);
  //background: none;
}


/deep/ .el-dialog__header {
  height: 5% !important;
  padding-top: 3px !important;
  padding-bottom: 0 !important;
}

/deep/ .el-dialog__body {
  height: 95% !important;
  padding: 5px 0 !important;
}

.el-table {
  font-size: 16px !important;

}

/deep/ .el-input--medium {
  font-size: 16px !important;

}

/deep/ .el-input__inner::placeholder {
  color: #544c4c !important;
  font-size: 16px;
  font-weight: bold;

}

/deep/ .el-input__inner {
  border: 1px solid #544c4c;
  color: #544c4c !important;
  font-size: 16px;

}


.normal {
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  //background-color: yellow;

  .search-area {
    margin: 20px 20px 0px 20px;
    text-align: left;
    font-size: 16px !important;
  }
}

.name_area {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 5px;
  overflow-x: auto;
  white-space: nowrap;
  line-height: 40px;
  //margin: 10px auto;
}

div {
  user-select: none; /* 禁止文本被选中 */
  -webkit-user-select: none; /* Chrome and Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}


</style>
